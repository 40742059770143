body,
html {
    scroll-behavior: smooth;
}

:focus {
    outline: none;
}

.booking-form form input,
::placeholder,
input,
textarea,
select {
    font-family: "Roboto", sans-serif;
    color: #777777;
}

.preloader {
    display: flex;
}

.offcanvas-wrapper .offcanvas-widget {
    overflow-x: auto;
}

header .nav-container.breakpoint-on .nav-menu .menu-items ul li.menu-item-has-children>a::after {
    content: "\f107";
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    display: block;
    position: absolute;
    right: 0;
    height: 45px;
    width: 45px;
    top: 0;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    z-index: 2;
    background: transparent;
    text-align: center;
    line-height: 45px;
    cursor: pointer;
    color: #fff;
    font-size: 20px;
}


/*header/ navbar*/

.nav-bar-text {
    font-size: 15px !important;
}

.nav-bar-title {
    font-size: 12px !important;
}


/*booking */

.booking-form form .inputs-filed .nice-select select {
    margin-left: 0;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-appearance: none;
}

.input-group.input-group-two .nice-select,
.booking-form form .inputs-filed .nice-select {
    padding: 0;
    border: none;
}

.booking-form form .nice-select,
.booking-form form input,
.booking-form form select {
    background-color: transparent;
    width: 100%;
    height: 50px;
    font-size: 14px;
    border: none;
    border-bottom: 2px solid #a14a25;
    line-height: 50px;
    color: #777777;
}

.input-group.input-group-two .nice-select::before {
    left: auto;
    right: 30px;
}

.input-group.input-group-two .nice-select select {
    margin-left: 0;
    padding: 0 50px 0 30px;
    -webkit-appearance: none;
    font-size: 14px;
}

.nice-select::before {
    content: '\f063';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 300;
    position: absolute;
    left: 30px;
    top: 50%;
    color: #a14a25;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__navigation {
    background: transparent;
    width: 10px;
    height: 10px;
    letter-spacing: normal;
    border: 0.45rem solid transparent;
}

input[type="date"]::placeholder {
    opacity: 0;
    pointer-events: none;
}

.inputDeparture::placeholder {
    opacity: 0;
}

.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--next {
    border-left-color: #ccc!important;
}

.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--previous {
    border-right-color: #ccc!important;
}

.latest-news .latest-post-box .post-desc .post-meta {
    font-size: 14px;
}

.latest-news .latest-post-box .post-desc .post-meta {
    margin-bottom: 20px;
    display: flex;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.latest-news .latest-post-box .post-desc h4 {
    margin-bottom: 15px;
}

.latest-news .latest-post-box .post-desc {
    padding: 25px;
}

.post-box .post-desc .post-footer .author img {
    width: 40px;
    height: 40px;
    object-fit: cover;
}

.news-details-box .entry-content .cat+.cat,
.post-box .post-desc .cat+.cat {
    margin-left: 15px;
}

.sidebar .widget.popular-feeds .single-popular-feed .feed-img {
    width: 80px;
    height: 80px;
}

.sidebar .widget.popular-feeds .single-popular-feed .feed-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.news-details-box .entry-footer .post-nav .next-post::before {
    content: '';
    background-image: url('assets/img/blog-details/icon.png');
    width: 40px;
    height: 40px;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
}

.counter-section .counter-box.counter-box-two h4 {
    display: inline-block;
}

.counter-section .counter-box.counter-box-two h4+.prefix {
    color: #222;
    letter-spacing: -1px;
    font-family: "Old Standard TT", serif;
    font-size: 60px;
    font-weight: 400;
    line-height: 1.2;
}

.amenities-list {
    margin: 10px;
    cursor: pointer;
}

.testimonial-img {
    height: 150px;
    width: 150px;
    object-fit: cover;
    border-radius: 50%;
}

.langSwitcherBtns {
    display: flex;
    align-items: center;
    margin: auto 0;
}

.langSwitcherBtns button {
    cursor: pointer;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    padding: 5px 8px;
    border: solid 1px;
    border-color: #c5c5c5;
    font-size: 12px;
}

.langSwitcherBtns button:focus {
    background-color: rgb(221, 221, 221);
}

.langSwitcherBtns button:hover {
    background-color: rgb(241, 241, 241);
}

.btnES {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.btnES.active {
    background-color: rgb(209, 209, 209);
}

.btnEN.active {
    background-color: rgb(209, 209, 209);
}

.btnES.no-active {
    background-color: white;
}

.btnEN {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.banner-area {
    margin-top: -50px;
}

.banner-area-img {
    margin-top: -80px;
}

@media (max-width:767px) {
    .news-details-box .entry-footer .post-nav .next-post::before {
        display: none;
    }
    .banner-area {
        margin-top: 50px;
    }
    .banner-area-img {
        margin-top: 50px;
    }
    /*container of room list scroll*/
    .container.col-lg-8 {
        height: 600px;
        overflow-y: scroll;
        margin-top: 20px;
    }
}


/*container of room list scroll*/

.room-list-loop {
    height: 1100px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.sub-title-roomlist {
    margin: 10px 0px;
    width: 15vw;
}


/*mandatory*/

.note {
    padding-bottom: 5px;
    padding-left: 55px;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 15px;
    font-size: 12px;
    width: 100%;
}

.confirm-page-btn {
    color: white;
    background-color: #7286D3;
    border: none;
    padding: 15px 10px;
    width: 20%;
    border-radius: 1px;
}

.confirm-page-btn:disabled {
    background-color: #d1d1d1;
    border: none;
    cursor: not-allowed;
}

.modal-email {
    font-size: 14px !important;
    margin-left: 5px;
}


/*confirmation component*/

.confirmation-container {
    background-color: white;
    position: absolute;
    right: 10%;
    top: 3.5%;
    width: 50vw;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 3px 5px;
    margin: 10px;
    padding: 10px 10px 10px;
}

.confirmation-title {
    display: flex;
    justify-content: center;
    color: #343434;
    font-weight: 500;
    font-size: 20px;
}

.main-title {
    padding-bottom: 10px;
    border-bottom: #eae9e9 1px solid;
}

.payment-row--alt {
    background-color: #f8f8f8;
}

#confirm-dates {
    font-weight: 600;
    font-size: 13px;
    color: #444444;
}

.confirmation-img {
    width: 50vw;
    padding: 5px 1px;
    display: flex;
    justify-content: center;
    margin: 5px auto;
}

.payment-confirmation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.payment-confirmation-title {
    color: #000;
    font-weight: 600;
    margin-top: 2px;
}

.payment-confirmation-title span {
    color: #777777;
    font-weight: 400;
}

.confirmation-price-text {
    font-size: 15px;
}

.confirmation-price-text-total {
    font-size: 18px;
    font-weight: 600;
    color: #181818;
}

.confirmation-price {
    font-size: 18px;
    color: #181818;
}

.btn-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    top: 40%;
}

.container-list-product-text {
    display: flex;
    flex-direction: column;
}


/*posible colors for button

pastel blue: 95BDFF
darker brown: 85586F
navy: 7286D3
red wine: 804674

*/

.confirmation-btn {
    border: none;
    background-color: #7286D3;
    position: absolute;
    bottom: 5%;
    color: white;
    padding: 10px;
    transition: 400ms;
    width: 50vw;
    margin: 0 auto;
}

.confirmation-btn p {
    color: white;
}

.confirmation-btn-no-selected {
    border: none;
    background-color: #d1d1d1;
    position: absolute;
    bottom: 5%;
    color: white;
    padding: 10px;
    transition: 400ms;
    width: 20vw;
    margin: 0 auto;
}

.confirmation-btn:hover {
    transform: scale(1.1);
}

@media only screen and (min-width: 1224px) {
    /*confirmation mobile*/
    .confirmation-desktop {
        display: block;
    }
    .confirmation-container-mobile {
        display: none;
    }
    .confirmation-container {
        background-color: white;
        width: 80%;
        height: auto;
    }
    .confirmation-btn {
        width: 90%;
        margin: 2px auto;
    }
    .confirmation-btn-no-selected {
        width: 90%;
        margin: 0px auto 5px;
    }
    .list-product {
        padding: 5px 0;
        display: flex;
        margin-left: 5%;
        margin-right: 5%;
        justify-content: space-between;
        border-bottom: #eae9e9 1px solid;
        align-items: center;
    }
    .fa-trash {
        cursor: pointer;
        transition: 200ms;
        color: red;
    }
    .fa-trash:hover {
        transform: scale(1.2);
    }
    .img-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .img-container-text {
        display: flex;
        flex-direction: column;
    }
    .confirmation-img {
        width: 80px;
        height: 60px;
        padding: 5px 1px;
        margin: 10px 10px 10px 0;
    }
    .img-container-title {
        font-size: 12px;
        color: #000;
        font-weight: 600;
    }
    .price-confirmation {
        display: flex;
        margin-right: 20px;
        align-items: center;
        font-size: 12px;
    }
    .payment-list {
        display: flex;
        justify-content: space-between;
    }
    .payment-confirmation {
        padding-top: 10px;
        margin-top: 10px;
        margin-left: 5%;
        margin-right: 5%;
        margin-bottom: 5%;
        display: flex;
        flex-direction: column;
    }
    .payment-table {
        margin-bottom: 5px;
    }
    .confirmation-price-container {
        border-top: #eae9e9 1px solid;
        margin-top: 10px;
        padding-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .breakingline {
        height: 1px;
        margin-top: 15px;
        background-color: #c2c2c2;
        width: 100%;
    }
    .confirmation-price-text {
        font-size: 14px;
    }
    .confirmation-price {
        font-size: 14px;
    }
    .btn-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 50px;
        margin-bottom: 30px;
    }
    .confirmation-tax-cancel {
        display: flex;
        flex-direction: column;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: -28px;
    }
    .tax-text {
        font-size: 11px;
        margin: 5px 0;
    }
    .cancel-text {
        font-size: 11px;
        margin: 5px 0;
    }
    .cancel-text span {
        font-weight: 600;
        color: #4f4f4f;
    }
    .tax-text span {
        font-weight: 600;
        color: #4f4f4f;
    }
    .currency-container {
        margin: 5px 0 1px;
        padding: 6px;
        display: flex;
        align-items: center;
        background-color: #f9f9f9;
    }
    .payment-label {
        padding: 3px 0;
    }
    #input-room {
        display: flex;
        flex-direction: column;
        margin-left: 5px;
    }
    .payment-label {
        font-size: 14px;
    }
    .discount-container {
        display: flex;
        align-items: center;
        width: 20vw;
    }
}

@media only screen and (max-width: 720px) {
    .room-list-loop {
        width: 70vw;
    }
    #price-list {
        margin-top: 20px;
    }
    #input-room {
        display: flex;
        flex-direction: column;
    }
    .confirmation-container-mobile {
        margin-bottom: -100px;
        margin-right: 60%;
        width: 100VW;
        margin-top: 10%;
        display: block;
    }
    .sub-title-roomlist {
        margin: 10px 0px;
        width: 80vw;
    }
    .confirmation-title {
        font-size: 15px;
        text-align: center;
    }
    .confirmation-container {
        position: relative;
        width: 85vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 0.1%;
    }
    .img-container-text {
        display: flex;
        justify-content: space-between;
        margin-left: 25%;
        margin-bottom: 20px;
        align-items: center;
    }
    .img-container-title {
        text-align: center;
    }
    #icon-mobile {
        margin-left: 10%;
        position: absolute;
        color: red;
        margin-top: -12px;
    }
    .confirmation-desktop {
        display: none;
    }
    .price-confirmation {
        display: flex;
        justify-content: flex-start;
        margin-top: 20px;
        color: #000;
        font-weight: 800;
    }
    .list-product {
        border-bottom: 1px solid rgb(197, 197, 197);
    }
    .confirmation-price-text {
        font-size: 14px;
    }
    .confirmation-price {
        font-size: 13px;
        margin-left: 5px;
    }
    .btn-wrapper {
        margin-top: 20px;
    }
    .confirmation-btn {
        border: none;
        margin: 5px;
        position: relative;
        width: 35vw;
        margin-top: -25px;
    }
    .confirmation-btn-no-selected {
        margin-top: 20px;
        width: 35vw;
        cursor: not-allowed;
    }
    .confirmation-price-container {
        border-top: #eae9e9 1px solid;
        margin-top: 50px;
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .payment-list {
        display: flex;
        justify-content: space-between;
    }
    .payment-confirmation {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        justify-content: space-between;
        margin: 10px;
    }
    .fa-trash {
        margin-left: 190px;
        margin-bottom: 20px;
        margin-top: -30px;
        position: absolute;
    }
    .confirm-page-btn {
        width: 60%;
        margin: 10px 0;
        border-radius: 1px;
    }
    .confirmation-tax-cancel {
        display: flex;
        flex-direction: column;
        margin-left: 8%;
        margin-right: 8%;
        margin-top: 10px;
    }
    .discount-container {
        display: flex;
        width: 60vw;
    }
    .tax-text {
        font-size: 12px;
        margin: 10px 0;
    }
    .cancel-text {
        font-size: 12px;
        margin: 5px 0;
    }
    .cancel-text span {
        font-weight: 600;
        color: #4f4f4f;
    }
    .tax-text span {
        font-weight: 600;
        color: #4f4f4f;
    }
    .currency-container {
        margin: 10px 0 25px;
        padding: 8px;
        display: flex;
        align-items: center;
        background-color: #f9f9f9;
    }
    .confirmation-price-container {
        border-top: #eae9e9 1px solid;
        margin-top: 10px;
        padding-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .payment-label {
        padding: 5px 0;
    }
    .note {
        padding-bottom: 5px;
        padding-left: 10px;
        border-bottom: 1px solid #f0f0f0;
        margin-bottom: 15px;
        font-size: 12px;
        width: 100%;
    }
}

.book-btn-confirmation {
    padding: 6px;
    font-size: 12px;
    border: none;
    background-color: #a14a25;
    color: white;
    margin-top: 1rem;
    width: 80%;
    transition: all 400ms;
}

.book-btn-confirmation:hover {
    background-color: #181818;
}

.book-btn-confirmation:focus {
    background-color: #181818;
}


/*button disabled*/

.book-btn-disabled {
    padding: 6px;
    font-size: 12px;
    cursor: not-allowed;
    border: none;
    background-color: #d4d4d4;
    color: white;
    margin-top: 1rem;
    width: 80%;
    transition: all 400ms;
}


/*details of the sidebar, hide when mobile, and block when desktop*/

#sidebar-desktop {
    display: none;
}

#sidebar-mobile {
    display: block;
}

@media only screen and (min-width: 1224px) {
    #sidebar-desktop {
        display: block;
    }
    #sidebar-mobile {
        display: none;
    }
}


/*details img*/

.details-img {
    width: 40vw;
}


/*details price*/

#price-list-line {
    text-decoration: line-through;
    margin-top: 5px;
    font-size: 12px;
}

#price-list {
    font-size: 13px;
    margin-top: 5px;
}

#price-list span {
    font-size: 13px;
}

.price-list-discount {
    color: #beab87;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 5px;
}

.booking-per-night {
    margin: 0 0 0 8px;
}


/*back to top btn*/

.top-to-btm {
    position: relative;
}

.icon-position {
    position: fixed;
    bottom: 65px;
    right: 25px;
    z-index: 40;
}

.icon-whatsapp {
    position: fixed;
    bottom: 65px;
    left: 25px;
    z-index: 40;
    background-color: #075e54!important;
    font-size: 20px;
}

.arrow-icon {
    margin: 15px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-style {
    background-color: #a14a25;
    height: 50px;
    width: 50px;
    color: #fff;
    cursor: pointer;
    animation: movebtn 3s ease-in-out infinite;
    transition: all .5s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 5px;
}

.icon-style:hover {
    background-color: #181818!important;
}


/*select input*/

.select-input {
    display: flex;
    width: 7vw;
    justify-content: center;
    border-bottom: 1px solid #a14a25;
    align-items: center;
    margin-top: 0.2rem;
    font-size: 15px;
}

.select-btn {
    background: none;
    border: none;
    font-size: 16px;
    padding: 0;
    cursor: pointer;
}

.select-btn:hover {
    color: #007bff;
}

.select-input p {
    border: none;
    font-size: 16px;
    text-align: center;
    width: 100%;
    padding: 0;
}

.input-group label {
    margin-right: 10px;
    padding-top: 20px;
}

.field-name {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #353535;
}

.error-msg {
    color: red;
    font-size: 12px;
}


/*mandatory asterisk*/

.mandatory {
    color: #ff0000;
    font-size: 2.5ch;
}

.asterisk {
    position: relative;
    font-size: 2.5ch;
    color: red;
    margin-left: -95px;
    margin-top: 25px;
}

.icon-mandatory {
    position: absolute;
    left: 15px;
    bottom: 25px;
    color: #a14a25;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
}

.mandatoryInput {
    height: 50px !important;
}

.mandatoryInput::placeholder {
    font-size: 12px;
}

.mandatoryInput:valid {
    border: none;
}

::placeholder {
    color: #999999;
}

.border-red {
    border: red 1px solid;
}

.border-red+.asterisk {
    display: none;
}


/*modal*/

.custom-modal .modal-content {
    border: none;
    background-color: #fff;
    height: 50vh;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 50vw;
    z-index: 2000;
}

.modal-title-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #eae9e9;
}

.modal-title-container i {
    font-size: 90px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #1bac1b;
}

.modal-title-container p {
    font-weight: 700;
    font-size: 22px;
    color: #1bac1b;
}

.modal-title-container span {
    margin: 20px 0 20px;
    display: flex;
    align-items: center;
    color: #343434;
    font-weight: 600;
    font-size: 13px;
}

.modal-title-container small {
    width: 60%;
    text-align: center;
    margin-bottom: 40px;
    font-size: 13px;
}

.modal-btn {
    margin-top: 20px;
    margin-bottom: 10px;
    background-color: #a14a25;
    border: none;
    border-radius: 1px;
    width: 35%;
    transition: 500ms;
}

.modal-btn:hover {
    background-color: #000;
    transform: scale(1.1);
}


/*select on booking form*/

.select-booking-input {
    display: flex;
    width: 80%;
    justify-content: center;
    align-items: flex-end;
    height: 35px;
    margin-top: 1rem;
    font-size: 16px;
}

.select-booking-input input {
    font-size: 16px;
    height: 40px !important;
    text-align: center;
    width: 100%;
    padding: 0;
}

.select-booking-btn {
    background: none;
    border: none;
    height: 40px !important;
    font-size: 16px;
    padding: 0;
    cursor: pointer;
}

.select-booking-btn p {
    font-size: 18px;
}

.select-btn {
    width: 50px;
    font-size: 18px;
}


/*go back btn*/

.goback-btn-container {
    margin-bottom: 15px;
    margin-top: -66px;
}

.goback-btn {
    background-color: #a14a25;
    border-radius: 2px;
    border: none;
    height: 55px;
    width: 80px;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    animation: movebtn 3s ease-in-out infinite;
    transition: all .5s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 5px;
}

.goback-btn:hover {
    transform: scale(1.1);
    background-color: #181818;
}


/*select in mobile size*/

@media only screen and (max-width: 720px) {
    .select-input {
        display: flex;
        width: 40vw;
        justify-content: center;
        border-bottom: 1px solid #a14a25;
        align-items: center;
        margin-top: 0.5rem;
        height: 80%;
        font-size: 16px;
    }
    .select-booking-btn p {
        font-size: 20px;
    }
}


/* cambios presik */

.hero-booking{
    background-color: #181818;
    display: flex;
    justify-content: center;
    z-index: 20;}